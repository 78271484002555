import { scrollIntoView } from 'seamless-scroll-polyfill'
import { nextTick } from 'vue'

export const scrollElIntoView = (
  element?: Element | null,
  options: ScrollIntoViewOptions = {},
) => {
  if (!element) {
    return
  }
  if (element?.scrollIntoView) {
    element.scrollIntoView(options)
  } else {
    // Support Safari browsers https://developer.mozilla.org/en-US/docs/Web/API/Element/scrollIntoView#browser_compatibility
    // const { scrollIntoView } = await import('seamless-scroll-polyfill') // causes rollup build error
    scrollIntoView(element, options)
  }
}

export const scrollToId = async (id?: string, timeout = 300) => {
  if (!id) {
    return
  }

  await nextTick()

  setTimeout(() => {
    const el = document.getElementById(id)

    if (!el) {
      return
    }
    scrollElIntoView(el, { block: 'center', behavior: 'smooth' })
  }, timeout)
}
